import * as React from "react"
import {useEffect, useRef, useState} from "react"

import Content from "../components/content"

import '../styles/global.scss'
import IconoComunicacion from '../images/icon-comunicacion.png'
import VideoIntro from '../videos/noticias-intro.mp4'
import VideoOutro from '../videos/noticias-outro.mp4'


const NoticiasPage = ({ data }) => {

  const [started, setStarted] = useState(false);
  const [introVideo, setIntroVideo] = useState(true);
  const [displayedEvent, setDisplayedEvent] = useState(null);
  const [outroVideo, setOutroVideo] = useState(false);
  const [noticias, setNoticias] = useState([]);

  const startedRef = useRef(started);
  const displayedEventRef = useRef(displayedEvent);
  const videoIntroRef = useRef(null);
  const videoOutroRef = useRef(null);

  startedRef.current = started;
  displayedEventRef.current = displayedEvent;

  const introEnd = () => {
    setIntroVideo(false)
    setDisplayedEvent(0)
  }

  const BroadSignPlay = () => {
    if (!started) {
      videoIntroRef.current.play();
    }
  }

  useEffect(() => {
    if (!startedRef.current) {
      setInterval(() => {
        if (window.startGo && !startedRef.current) {
          window.startGo = false;
          setStarted(true);
          BroadSignPlay();
        }
      }, 500)
    }
  }, [started])

  useEffect(() => {
    const _noticias = [];
    data.noticias.edges.sort(() => 0.5 - Math.random()).slice(0, 1).forEach((edge) => {
      _noticias.push({
        titulo: edge.node.titulo_es,
        imagen: edge.node.imagen
      })

      _noticias.push({
        titulo: edge.node.titulo_eus,
        imagen: edge.node.imagen
      })
    });
    setNoticias(_noticias);
  }, [])

  useEffect(() => {
    let interval;
    if (!introVideo && !outroVideo) {
      interval = setInterval(() => {
        let currDisplayedEvent = displayedEventRef.current;
        //console.log('transition', currDisplayedEvent, noticias.length - 1, currDisplayedEvent + 1 < noticias.length - 1 )
        if (currDisplayedEvent + 1 < noticias.length) {
          setDisplayedEvent(currDisplayedEvent => currDisplayedEvent + 1)
        } else {
          setOutroVideo(true)
          setDisplayedEvent(null)
          videoOutroRef.current.play();
        }
      }, 6000)
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [introVideo, outroVideo]);

  return (
    <main className="main noticias">
      <video ref={videoIntroRef} style={{opacity: introVideo ? 1 : 0}} src={VideoIntro} autoPlay={false} controls={false} muted={true} onEnded={() => {introEnd()}}/>

      <section className={`header-container flex-set flex--row flex--align-items-center flex--content-center ${introVideo ? '' : 'slideInDown'}`}>
        <img src={IconoComunicacion} className="header-icon" alt=""/>
        <span className="header-text text-uppercase">Gaur Igartzan</span>
      </section>

      {noticias.map((noticia, index) =>
        <Content item={noticia} show={displayedEventRef.current === index && !introVideo && !outroVideo} key={index}/>
      )}

      <video ref={videoOutroRef} style={{opacity: outroVideo ? 1 : 0}} src={VideoOutro} autoPlay={false} controls={false} muted={true}/>

    </main>
  )
}

export const pageQuery = graphql`  
  {
    noticias: allStrapiNoticias {
      edges {
        node {
          id
          titulo_es
          titulo_eus
          imagen {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default NoticiasPage
